<template>
  <BCard id="scroll">
    <BRow class="items-center">
      <BCol lg="6">
        <strong class="text-black text-bold text-xl">Pengembalian Paket</strong>
      </BCol>
      <BCol lg="6">
        <BRow class="justify-end mr-1">
          <BFormSelect
            v-model="filter"
            placeholder="Pilih Status"
            class="mr-1"
            style="width: 30%"
            :options="filterOptions"
          />
          <BFormInput
            v-model="search"
            placeholder="Cari seller atau no. resi"
            style="width: 40%"
            @input="searchData"
          />
        </BRow>
      </BCol>
    </BRow>
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        :items="items"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-2"
        hover
      >
        <template #cell(seller_name)="data">
          <div style="min-width: 145px !important">
            {{ data.item.seller_name }}
          </div>
        </template>
        <template #cell(transaction_value)="data">
          <div style="min-width: 145px !important">
            {{ IDR(data.item.transaction_value) }}
          </div>
        </template>
        <template #cell(cnote)="data">
          <div style="min-width: 150px !important">
            <img
              :src="data.item.shipping_image_path"
              alt="Komerce"
              style="width: 50px"
              class="m-auto"
            >
            <BRow class="flex items-center justify-center">
              <span>{{ data.item.cnote }}</span>
              <BImg
                src="https://storage.googleapis.com/komerce/assets/icons/icons-copy.svg"
                class="ml-[5px] cursor-pointer w-5"
                @click="toastCopyResi(data.item.cnote)"
              />
            </BRow>
          </div>
        </template>
        <template #cell(date_retur)="data">
          <div style="min-width: 145px !important">
            {{ DDMMYYYY(data.item.date_retur) }}
          </div>
        </template>
        <template #cell(last_update)="data">
          <div style="min-width: 100px !important">
            {{ DDMMYYYY(data.item.last_update) }}
          </div>
        </template>
        <template #cell(status_retur)="data">
          <div
            style="min-width: 145px !important"
            :class="classStatus(data.item.status_retur)"
          >
            {{ labelStatus(data.item.status_retur) }}
          </div>
        </template>
        <template #cell(status_follow_up)="data">
          <div
            v-if="data.item.status_follow_up === 1"
            v-b-tooltip.hover.top="`Status diubah pada : ${DDMMYYYY(data.item.log_retur_upated_at)}`"
            style="min-width: 145px !important"
            class="cursor-pointer"
            :class="classHistory(data.item.status_follow_up)"
          >
            {{ labelHistory(data.item.status_follow_up) }}
          </div>
          <div
            v-else
            style="min-width: 145px !important"
            :class="classHistory(data.item.status_follow_up)"
          >
            {{ labelHistory(data.item.status_follow_up) }}
          </div>
        </template>
        <template #cell(detail)="data">
          <span
            class="font-semibold cursor-pointer"
            style="color: #08A0F7"
            @click="handleClick(data.item.order_id)"
          >
            Detail
          </span>
        </template>
      </BTable>
    </BOverlay>
  </BCard>
</template>

<script>
import {
  BCard, BRow, BFormSelect, BFormInput, BImg,
} from 'bootstrap-vue'
import { IDR } from '@/libs/currency'
import { DDMMYYYY } from '@/libs/filterDate'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { komshipAxiosIns } from '@/libs/axios'
import { configTable, filterOptions } from './config'

export default {
  components: {
    BCard, BRow, BFormSelect, BFormInput, BImg,
  },
  data() {
    return {
      items: [],
      fields: configTable,
      IDR,
      DDMMYYYY,
      filter: null,
      filterOptions,
      isLoading: false,
      limit: 25,
      offset: 0,
      lastData: false,
      search: '',
    }
  },
  watch: {
    filter: {
      handler() {
        this.getData()
      },
    },
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('scroll').offsetHeight && !this.isLoading) {
        this.getNextData()
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      this.offset = 0
      const url = '/v1/ticket-admin/list/package-return'
      const params = {
        status: this.filter,
        limit: this.limit,
        offset: this.offset,
        search: this.search,
      }
      await komshipAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.items = data
          this.isLoading = false
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, 2000)
          this.isLoading = false
        })
    },
    async getNextData() {
      if (!this.lastData) {
        this.isLoading = true
        const url = '/v1/ticket-admin/list/package-return'
        const params = {
          status: this.filter,
          limit: this.limit,
          offset: this.offset,
          search: this.search,
        }
        await komshipAxiosIns.get(url, { params })
          .then(result => {
            const { data } = result.data
            this.items.push(...data)
            this.offset += data.length
            this.isLoading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            })
          })
      }
    },
    searchData: _.debounce(function search() {
      this.getData()
    }, 1000),
    toastCopyResi(number) {
      navigator.clipboard.writeText(number)
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: `Resi ${number} berhasil disalin`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      }, 1000)
    },
    labelStatus(status) {
      if (status === 0) return 'Belum Retur'
      if (status === 1) return 'Perjalanan Pulang'
      if (status === 2) return 'Paket Diterima'
      if (status === 3) return 'Paket Rusak'
      if (status === 4) return 'Paket Hilang'
      return ''
    },
    classStatus(status) {
      if (status === 0) return 'notRetur retur'
      if (status === 1) return 'ontheway retur'
      if (status === 2) return 'receivePackage retur'
      if (status === 3) return 'brokenPackage retur'
      if (status === 4) return 'brokenPackage retur'
      return ''
    },
    labelHistory(status) {
      if (status === 0) return 'Perlu Diproses'
      if (status === 1) return 'Sedang Diproses'
      return ''
    },
    classHistory(status) {
      if (status === 0) return 'needProccess'
      if (status === 1) return 'beingProccess'
      return ''
    },
    handleClick(idOrder) {
      this.$router.push({ path: `/tiket/detail/order/${idOrder}` })
    },
  },
}
</script>
<style scoped lang="scss">
@import './PackageReturn.scss';
</style>
