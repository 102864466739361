/* eslint-disable import/prefer-default-export */
export const configTable = [
  {
    key: 'seller_name',
    label: 'Seller',
    class: 'text-black align-middle p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
  },
  {
    key: 'cnote',
    label: 'Resi',
    class: 'text-black text-center align-middle p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
  },
  {
    key: 'date_retur',
    label: 'Diputuskan Retur',
    class: 'text-black align-middle p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
    sortable: true,
    tdClass: 'text-center',
  },
  {
    key: 'last_update',
    label: 'Last Update',
    class: 'text-black align-middle p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
    sortable: true,
    tdClass: 'text-center',
  },
  {
    key: 'transaction_value',
    label: 'Nilai Transaksi',
    class: 'text-black align-middle p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
    sortable: true,
  },
  {
    key: 'detail',
    label: 'Detail',
    class: 'text-black align-middle p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
    tdClass: 'text-center',
  },
  {
    key: 'status_retur',
    label: 'Status Retur',
    class: 'text-black align-middle p-0',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
    tdClass: 'text-center',
  },
  {
    key: 'status_follow_up',
    label: 'Riwayat Action',
    class: 'text-black align-middle p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
    tdClass: 'text-center',
  },
]

export const filterOptions = [
  {
    value: null,
    text: 'Pilih Status',
    disabled: true,
  },
  {
    value: '',
    text: 'Semua',
  },
  {
    value: 0,
    text: 'Perlu Diproses',
  },
  {
    value: 1,
    text: 'Sedang Diproses',
  },
]
